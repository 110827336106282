/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import 'bootstrap/functions';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
// Variables
@import 'app/common/variables';
// Layout
@import 'app/layout/layout';
@import 'app/layout/layout-extra';
@import 'app/layout/layout-animation';
@import 'app/layout/top-navbar';
@import 'app/layout/sidebar';
@import 'app/layout/offsidebar';
@import 'app/layout/user-block';
@import 'app/layout/settings';
// Common
@import 'app/common/page-loader';
@import 'app/common/typo';
@import 'app/common/bootstrap-reset';
@import 'app/common/bootstrap-custom';
@import 'app/common/button-extra';
@import 'app/common/placeholder';
@import 'app/common/cards';
@import 'app/common/circles';
@import 'app/common/dropdown-extra';
@import 'app/common/half-float';
@import 'app/common/animate';
@import 'app/common/slim-scroll';
@import 'app/common/inputs';
@import 'app/common/utils';
@import 'app/common/print';

// Extras
@import 'app/extras/timeline';

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&display=swap');

body {
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Kanit', sans-serif;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Kanit', sans-serif;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Kanit', sans-serif;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Kanit', sans-serif;
}

.card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
  @include media-breakpoint-only(xl) {
    column-count: 3;
  }
}

.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}

.error-actions .btn {
  margin-right: 10px;
}

.scrolling-wrapper {
  padding-bottom: 0.5rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .card {
    display: inline-block;
  }
}

.task-indicator {
  position: absolute;
  width: 3px;
  height: 80%;
  border-radius: 0.3rem;
  // left: 0.625rem;
  padding-left: 2px;
  top: 10%;
  opacity: 0.9;
  transition: opacity 0.2s;
}

.input-min-height {
  min-height: 38px;
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 400px; /* change this to whatever you want */
  width: auto;
}

.hand-pointer {
  cursor: pointer;
}

.backlog-item {
  min-height: 58px;
}

.my-swal2 {
  z-index: 20000 !important;
}

/** 
badge
*/

.badge-yellow {
  color: $white;
  background-color: $yellow;
}

.badge-gray {
  color: $white;
  background-color: $gray-dark;
}

.btn-yellow {
  color: $white;
  background-color: $yellow;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #007bff; /* Change this to your desired color */
  border-radius: 50%;
  padding: 10px;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background-color: #0056b3; /* Change this to your desired hover color */
}

.custom-table th, .custom-table td {
  text-align: left;
  padding: 10px;
}

.custom-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

